import { create } from 'apisauce';

const api = create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

export default {
  isServerDown: async () => {
    const response = await api
      .get('/healthcheck');

    // eslint-disable-next-line no-console
    console.info('Checking if the server is down.', response);
    if (!response.ok) {
      return true;
    }

    return false;
  },
};
